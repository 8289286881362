// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".RenderWindowWithControlBar-module-rootContainer_1lck8 {\n  position: relative;\n}\n\n.RenderWindowWithControlBar-module-renderWindow_FOOWk {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.RenderWindowWithControlBar-module-control_1muSX {\n  z-index: 1;\n  position: absolute;\n}\n", ""]);
// Exports
exports.locals = {
	"rootContainer": "RenderWindowWithControlBar-module-rootContainer_1lck8",
	"renderWindow": "RenderWindowWithControlBar-module-renderWindow_FOOWk",
	"control": "RenderWindowWithControlBar-module-control_1muSX"
};
module.exports = exports;
