// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Slider-module-cursor_54qNM {\n  position: absolute;\n  border-radius: 50%;\n  box-sizing: border-box;\n  cursor: move;\n}\n", ""]);
// Exports
exports.locals = {
	"cursor": "Slider-module-cursor_54qNM"
};
module.exports = exports;
