export const Coordinate = {
  DISPLAY: 0,
  NORMALIZED_DISPLAY: 1,
  VIEWPORT: 2,
  NORMALIZED_VIEWPORT: 3,
  PROJECTION: 4,
  VIEW: 5,
  WORLD: 6,
};

export default {
  Coordinate,
};
