// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CornerAnnotation-module-container_kpViB {\n  display: flex;\n  align-items: stretch;\n  justify-content: space-between;\n  flex-direction: column;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  z-index: 100;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n\n.CornerAnnotation-module-row_nWtUc {\n  flex: 1;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n}\n\n.CornerAnnotation-module-topRow_Bl3Yk {\n  align-items: flex-start;\n}\n\n.CornerAnnotation-module-middleRow_17aXO {\n  align-items: center;\n}\n\n.CornerAnnotation-module-bottomRow_23eFY {\n  align-items: flex-end;\n}\n\n.CornerAnnotation-module-item_3a4j2 {\n  flex: 1;\n  padding: 5px;\n}\n\n.CornerAnnotation-module-east_symrz {\n  text-align: right;\n}\n\n.CornerAnnotation-module-west_1UDv9 {\n  text-align: left;\n}\n\n.CornerAnnotation-module-north_FnFzY {\n  text-align: center;\n}\n\n.CornerAnnotation-module-south_2Yedl {\n  text-align: center;\n}\n\n.CornerAnnotation-module-northEast_teubZ {\n}\n\n.CornerAnnotation-module-northWest_3jz2J {\n}\n\n.CornerAnnotation-module-southEast_2VhvQ {\n}\n\n.CornerAnnotation-module-southWest_1HXje {\n}\n\n", ""]);
// Exports
exports.locals = {
	"container": "CornerAnnotation-module-container_kpViB",
	"row": "CornerAnnotation-module-row_nWtUc",
	"topRow": "CornerAnnotation-module-topRow_Bl3Yk CornerAnnotation-module-row_nWtUc",
	"middleRow": "CornerAnnotation-module-middleRow_17aXO CornerAnnotation-module-row_nWtUc",
	"bottomRow": "CornerAnnotation-module-bottomRow_23eFY CornerAnnotation-module-row_nWtUc",
	"item": "CornerAnnotation-module-item_3a4j2",
	"east": "CornerAnnotation-module-east_symrz CornerAnnotation-module-item_3a4j2",
	"west": "CornerAnnotation-module-west_1UDv9 CornerAnnotation-module-item_3a4j2",
	"north": "CornerAnnotation-module-north_FnFzY CornerAnnotation-module-item_3a4j2",
	"south": "CornerAnnotation-module-south_2Yedl CornerAnnotation-module-item_3a4j2",
	"northEast": "CornerAnnotation-module-northEast_teubZ CornerAnnotation-module-east_symrz CornerAnnotation-module-item_3a4j2",
	"northWest": "CornerAnnotation-module-northWest_3jz2J CornerAnnotation-module-west_1UDv9 CornerAnnotation-module-item_3a4j2",
	"southEast": "CornerAnnotation-module-southEast_2VhvQ CornerAnnotation-module-east_symrz CornerAnnotation-module-item_3a4j2",
	"southWest": "CornerAnnotation-module-southWest_1HXje CornerAnnotation-module-west_1UDv9 CornerAnnotation-module-item_3a4j2"
};
module.exports = exports;
