export const InteractionState = {
  OUTSIDE: 0,
  NEARBY: 1,
  SELECTING: 2,
  TRANSLATING: 3,
  SCALING: 4,
};

export default {
  InteractionState,
};
