// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FPSMonitor-module-verticalContainer_3rIaH {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: stretch;\n}\n\n.FPSMonitor-module-horizontalContainer_2amLm {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n}\n\n.FPSMonitor-module-leftPane_3WdS0 {\n  flex: none;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: stretch;\n}\n\n.FPSMonitor-module-rightPane_SL07L {\n  flex: 1;\n  display: grid;\n  grid-template-columns: auto auto;\n  grid-auto-rows: 1.5em;\n  grid-column-gap: 5px;\n  grid-row-gap: 2px;\n  padding: 10px;\n}\n\n.FPSMonitor-module-title_k0826 {\n  flex: 1;\n  font-weight: bold;\n  padding: 5px 10px 0 10px;\n}\n\n.FPSMonitor-module-graph_1VXYB {\n  flex: none;\n  border: solid 1px black;\n  margin: 10px ;\n  border-radius: 2px;\n  overflow: hidden;\n}\n\n.FPSMonitor-module-label_1ehje {\n  font-weight: bold;\n  text-transform: capitalize;\n  text-align: right;\n  align-self: center;\n}\n\n.FPSMonitor-module-value_nNv5Y {\n  font-style: italic;\n  text-align: center;\n  align-self: center;\n}\n", ""]);
// Exports
exports.locals = {
	"verticalContainer": "FPSMonitor-module-verticalContainer_3rIaH",
	"horizontalContainer": "FPSMonitor-module-horizontalContainer_2amLm",
	"leftPane": "FPSMonitor-module-leftPane_3WdS0",
	"rightPane": "FPSMonitor-module-rightPane_SL07L",
	"title": "FPSMonitor-module-title_k0826",
	"graph": "FPSMonitor-module-graph_1VXYB",
	"label": "FPSMonitor-module-label_1ehje",
	"value": "FPSMonitor-module-value_nNv5Y"
};
module.exports = exports;
