export const PassTypes = {
  MIN_KNOWN_PASS: 0,
  ACTOR_PASS: 0,
  COMPOSITE_INDEX_PASS: 1,
  ID_LOW24: 2,
  MAX_KNOWN_PASS: 2,
};

export default {
  PassTypes,
};
